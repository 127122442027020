import React from "react"
import "../index.css"

import { Box, Center, Grid, Image } from "@chakra-ui/react"
import { Trans } from "gatsby-plugin-react-i18next"
import { contentPicture } from "../../utils/descriptions"

const FeatureOne = () => {
  return (
    <Box
      mx={{ "2xl": "18rem", xl: "10rem", lg: "5rem", base: "1rem" }}
      mt={"32"}
    >
      <Grid
        templateColumns={{ lg: "repeat(2, 1fr)", base: "repeat(1, 1fr)" }}
        gap={{ "2xl": 26, xl: 21, lg: 10, sm: 15, base: 5 }}
      >
        <Box position={"relative"}>
          <Center>
            <Image
              src={contentPicture.picture1Img}
              w={{ lg: "100%", sm: "60%" }}
              alt="offlineImg"
            />
          </Center>
        </Box>
        <Box mt={{ xl: "4", lg: "2", base: "0.5" }}>
          <Box
            fontWeight={700}
            fontSize={{ "2xl": 40, xl: 35, lg: 25, base: 20 }}
            mr="24"
          >
            No more long lines. <span style={{ color: "#3952D3" }}>Online</span>{" "}
            and <span style={{ color: "#8326EA" }}>Offline Enrollment</span>.
          </Box>
          <Box
            w="100%"
            fontSize={{ xl: 15, base: 14 }}
            mt={{ xl: "5", base: "3" }}
            fontWeight={500}
          >
            <Trans>{contentPicture.picture1Desc}</Trans>
          </Box>
        </Box>
      </Grid>
    </Box>
  )
}

export default FeatureOne
